import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const ProjectsPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Projects" />
      <h1>Projects</h1>
      {data.allMdx.group.map(group => (
        <ProjectPreview key={group.fieldValue}>
          <ProjectTitle>
            <Link to={`/project/${group.fieldValue}`}>{group.fieldValue}</Link>
          </ProjectTitle>
          <p>
            {group.totalCount} {group.totalCount === 1 ? "Post" : "Posts"}
            {" | Last posted "}
            <Link to={`/blog/${group.nodes[0].slug}`}>
              {group.nodes[0].frontmatter.title}
            </Link>{" "}
            on {group.nodes[0].frontmatter.date}
          </p>
        </ProjectPreview>
      ))}
    </Layout>
  )
}

const ProjectTitle = styled.p`
  font-size: 1.5em;
  font-weight: bold;
`

const ProjectPreview = styled.div`
  h2 {
    margin: 0.3em 0;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0.25em 0;
  }
`

export const query = graphql`
  {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
      group(field: frontmatter___project, limit: 1) {
        fieldValue
        totalCount
        nodes {
          slug
          frontmatter {
            title
            date(formatString: "MMM D, YYYY")
            spoiler
          }
        }
      }
    }
  }
`

export default ProjectsPage
